import React, { useEffect,useRef } from 'react';
import Style from '../styles/About.module.css'
function AboutTeam() {
    const columnRefs = useRef([]);
    useEffect(() => {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                entry.target.classList.add('visible');
              } else {
                entry.target.classList.remove('visible');
              }
            });
          },
          { threshold: 0.1 }
        );
    
        columnRefs.current.forEach((columnRef) => {
          if (columnRef) {
            observer.observe(columnRef);
          }
        });
    
        return () => {
          observer.disconnect();
        };
      }, []);
    
      const handleColumnRef = (ref) => {
        if (ref && !columnRefs.current.includes(ref)) {
          columnRefs.current.push(ref);
        }
      };
  
    return (
        <>
            <section className={Style.teamsec}>
                <div className={`${Style.aboutcontent}`}>
                    <h2><span className ={Style.star}>*</span>A Powerhouse <u>team with expertise</u>, delivering <mark>amazing results!</mark></h2>
                    <p>Our team is a dynamic blend of seasoned professionals and rising stars, bringing a wealth of specialised knowledge to deliver exceptional results.</p>
                </div>
                <div className={`${Style.aboutrow}`}>
                    {/* col 1 */}
                    <div className={`${Style.aboutcol}`} ref={handleColumnRef}>
                        <div className={`${Style.aboutcard}`}>
                            <div className={Style.cardinner}>
                                <div className={`${Style.card_front}`}>
                                    <img src="../images/arsh.jpg" alt="" style={{borderRadius:"6%"}} />
                                </div>
                                <div className={`${Style.card_back}`}>
                                <video
                                        loop
                                        style={{ width: "100%"  , borderRadius:"6%" }}
                                        autoPlay
                                        muted
                                        src="/videos/1.mp4"
                                    ></video>
                                </div>
                            </div>
                            <h6>Arshdeep Singh</h6>
                            <p>Founder & Chief Executive Officer</p>
                        </div>

                    </div>
                    {/* col 2 */}
                    <div className={`${Style.aboutcol}`} ref={handleColumnRef}>
                        <div className={`${Style.aboutcard}`}>
                            <div className={Style.cardinner}>
                                <div className={`${Style.card_front}`}>
                                    <img src="../images/sashank.jpeg" alt="" style={{borderRadius:"6%"}} />
                                </div>
                                <div className={`${Style.card_back}`}>
                                      <video
                                        loop
                                        style={{ width: "100%" , borderRadius:"6%"  }}
                                        autoPlay
                                        muted
                                        src="/videos/2.mp4"
                                    ></video>
                                </div>
                            </div>
                            <h6>Shashank Chhabra</h6>
                            <p>Founder & Managing Director</p>
                        </div>

                    </div>
                    {/* col 3 */}
                    <div className={`${Style.aboutcol}`} ref={handleColumnRef}>
                        <div className={`${Style.aboutcard}`}>
                            <div className={Style.cardinner}>
                                <div className={`${Style.card_front}`}>
                                    <img src="../images/sandeep.jpg" alt="" style={{borderRadius:"6%"}} />
                                </div>
                                <div className={`${Style.card_back}`}>
                                        <video
                                        loop
                                        style={{ width: "100%" , borderRadius:"6%"  }}
                                        autoPlay
                                        muted
                                        src="/videos/3.mp4"
                                    ></video>
                                </div>
                            </div>
                            <h6>Sandeep Jha</h6>
                            <p>Chief Operating Officer</p>
                        </div>

                    </div>
                    {/* col 4 */}
                    <div className={`${Style.aboutcol}`} ref={handleColumnRef}>
                        <div className={`${Style.aboutcard}`}>
                            <div className={Style.cardinner}>
                                <div className={`${Style.card_front}`}>
                                    <img src="../images/mat.jpg" alt="" style={{borderRadius:"6%"}} />
                                </div>
                                <div className={`${Style.card_back}`}>
                                     <video
                                        loop
                                        style={{ width: "100%" , borderRadius:"6%"  }}
                                        autoPlay
                                        muted
                                        src="/videos/4.mp4"
                                    ></video>
                                </div>
                            </div>
                            <h6>Mateo De la Hoz</h6>
                            <p>Chief Technical Officer</p>
                        </div>

                    </div>
                    {/* col 5 */}
                    <div className={`${Style.aboutcol}`} ref={handleColumnRef}>
                        <div className={`${Style.aboutcard}`}>
                            <div className={Style.cardinner}>
                                <div className={`${Style.card_front}`}>
                                    <img src="../images/shubham.jpg" alt="" style={{borderRadius:"6%"}} />
                                </div>
                                <div className={`${Style.card_back}`}>
                                    <video
                                        loop
                                        style={{ width: "100%" , borderRadius:"6%"  }}
                                        autoPlay
                                        muted
                                        src="/videos/5.mp4"
                                    ></video>
                                </div>
                            </div>
                            <h6>Shubham Dhamija</h6>
                            <p>Strategy Head</p>
                        </div>

                    </div>
                    {/* col 6 */}
                    <div className={`${Style.aboutcol}`} ref={handleColumnRef}>
                        <div className={`${Style.aboutcard}`}>
                            <div className={Style.cardinner}>
                                <div className={`${Style.card_front}`}>
                                    <img src="../images/tanveer.jpg" alt="" style={{borderRadius:"6%"}} />
                                </div>
                                <div className={`${Style.card_back}`}>
                                     <video
                                        loop
                                        style={{ width: "100%" , borderRadius:"6%"}}
                                        autoPlay
                                        muted
                                        src="/videos/6.mp4"
                                    ></video>
                                </div>
                            </div>
                            <h6>Tanveer Singh</h6>
                            <p>Delivery Head</p>
                        </div>

                    </div>

                </div>
            </section>
        </>
    );
}

export default AboutTeam;